import React, { useState } from "react";
import industry from "./industry.png";
import AddUserPermission from "./AddUserPermission";
import UserEntry from "./UserEntry";
import { removeUserPermission, addPlantLogo, openSubscriptionDialog, changeDisplayName, removePlant } from "../actions";
import { connect } from "react-redux";
import { compose } from "redux";

const OnlineIndicator = ({ online }) => {
  return <span style={{ fontFamily: "monospace", fontSize: 10 }}>Status:
    {
      online ? <span style={{ color: "#1a1" }}>online</span> : <span style={{ color: "#a11" }}>offline</span>
    }
  </span>
}

const Plant = ({ plant, dispatch }) => {

  const [plantLogo, setPlantLogo] = useState(plant.plantLogo);
  const [displayName, setDisplayName] = useState(plant.displayName);

  const uploadImage = () => {
    if (plantLogo === plant.plantLogo)
      alert("PlantLogo Empty");
    else
      dispatch(addPlantLogo(plant.plantName, plantLogo));
  }

  const uploadDisplayName = () => {
    if (displayName === "" || displayName === plant.displayName)
      alert("When displayname empty, the Plantname is used");
    // displayName is used either way
    dispatch(changeDisplayName(plant.plantName, displayName));
  }

  const deletePlant = () => {
    if(window.confirm("plant wirklich löschen?")){
      dispatch(removePlant(plant.plantName));
    }
  }

  const plantLogoBase64String = () => {
    return "data:image/png;base64," + plant.plantLogo;
  }

  const imageChanged = (e) => {
    const image = e.target.files[0] || e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(image);
    // STAT-351: This should use intermediate state to store the result of the base64 decoding and use that to update
    // Possibly split this into smaller components
    reader.onloadend = function () {
      const base64 = btoa(reader.result);
      setPlantLogo(base64);
      document.getElementById("result" + plant.plantName).src = URL.createObjectURL(image);
    };
  }

  return <div style={{ backgroundColor: "#ddd", width: 544, marginBottom: 20 }}>
    <div style={{ width: 504, marginLeft: 20, marginRight: 20, display: "inline-block" }}>
      <div style={{ float: "left" }}>
        <h3 style={{ marginTop: 20, fontSize: 18 }}><img src={industry} alt={"plant"} style={{ marginRight: 5 }} />{plant.plantName}</h3>
        <OnlineIndicator online={plant.online} />
        {
          plant.initialPassword &&
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginRight: 10 }}>Password: </div>
            <div style={{ fontFamily: "monospace", fontSize: 14 }}>{plant.initialPassword}</div>
          </div>
        }
        <div style={{ display: "flex", alignItems: "flex-end", marginTop: 5 }}>
          <div style={{ marginRight: 10 }}>Abo läuft bis: </div>
          <div className="subscriptionExpireDate" style={{ fontFamily: "monospace", fontSize: 14, marginRight: 16 }}>{plant.subscriptionDeadline}</div>
          <button id={"changeExpiredDateButton"} onClick={() => dispatch(openSubscriptionDialog(plant.plantName))}>Verlängern/Ändern</button>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end", marginTop: 5 }}>
          <div style={{ marginRight: 10 }}>Plant Logo: </div>
          <input type="file" id={"imageSelection" + plant.plantName} accept="image/png" onChange={(e) => imageChanged(e)} />
          <button id={"uploadButton" + plant.plantName} onClick={uploadImage}>Upload</button>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end", marginTop: 5 }}>
          <div style={{ marginRight: 10 }}>Displayname: </div>
          <input style={{ marginRight: 10 }} type="text" maxLength={20} placeholder={plant.plantName} value={displayName??""} onChange={(e) => setDisplayName(e.target.value)} />
          <button id={"uploadButtonDisplayName" + plant.plantName} onClick={uploadDisplayName}>Upload</button>
        </div>
      </div>
      <div style={{ float: "right", marginTop: 20, alignContent: "end" }}>
        <img id={"result" + plant.plantName} width="100" src={plantLogoBase64String()} alt="PlantLogo" />
      </div>
    </div>
    <div>
      <ol>
        {plant.users.map((user, userIndex) => {
          return <li key={userIndex}>
            <UserEntry eMail={user.eMail} isAdmin={user.administrator} />
            <button onClick={() => dispatch(removeUserPermission(user.eMail, plant.plantName))}
              style={{ marginLeft: 10 }}>Remove
            </button>
          </li>
        })}
      </ol>
      <AddUserPermission plant={plant.plantName} />
    </div>
    <div>
      <button style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} id={"removePlantButton" + plant.plantName} onClick={deletePlant}>Plant Löschen</button>
    </div>
  </div >;
};

export default compose(connect())(Plant);
