import React from "react";
import {createStructuredSelector} from "reselect";
import {compose} from "redux";
import {connect} from "react-redux";
import RegisterPlant from "./RegisterPlant";
import PrintPlant from "./PrintPlant";
import Plant from "./Plant";

const PlantList = ({plants}) => {
  const style = {
    margin: 10,
  };

  return <div style={style}>
    <h1>Plants</h1>
    {plants.map((plant, index) => {
      return <Plant key={index} plant={plant}/>
    })}
    <div style={{marginBottom:20}}>
      <RegisterPlant/>
    </div>  
    <PrintPlant/>
  </div>
};

const mapStateToProps = createStructuredSelector({
  plants: state => state.plants.plants,
});

export default compose(connect(mapStateToProps))(PlantList);
