import {
  CLEAR_ERRORS,
  failed,
  LOAD_PLANTS,
  LOAD_USERS,
  ADD_USER_PERMISSION,
  REGISTER_PLANT,
  REGISTER_USER, REMOVE_USER_PERMISSION, completed, LOGIN, REMOVE_USER
} from "../actions/names";

const DEFAULT_STATE = {
  list: []
};

const addError = (state, message) => {
  return {
    ...state,
    list: [...state.list, message],
  }
};

const errorList = (state = DEFAULT_STATE, action) => {
  switch(action.type) {
    case failed(LOAD_PLANTS): {
      return addError(state, "Unable to load plant list: " + action.payload);
    }
    case failed(LOAD_USERS): {
      return addError(state, "Unable to load user list: " + action.payload);
    }
    case failed(ADD_USER_PERMISSION): {
      return addError(state, "Error adding user permission: " + action.error);
    }
    case failed(REMOVE_USER_PERMISSION): {
      return addError(state, "Error removing user permission: " + action.error);
    }
    case failed(REGISTER_PLANT): {
      return addError(state, "Unable to register plant: " + action.error);
    }
    case failed(REGISTER_USER): {
      return addError(state, "Unable to register user: " + action.error);
    }
    case failed(REMOVE_USER): {
      return addError(state, "Unable to remove user: " + action.error);
    }
    case completed(LOGIN):
    case CLEAR_ERRORS: {
      return {
        ...state,
        list: [],
      }
    }
    default: {
      return state;
    }
  }
};

export default errorList;
