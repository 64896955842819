import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {createStructuredSelector} from "reselect";
import LoginScreen from "./LoginScreen";

const LoginGate = ({token, children}) => {
  if (token !== null) {
    return children;
  }

  return <LoginScreen/>
};

const mapStateToProps = createStructuredSelector({
  token: state => state.application.loginToken
});

export default compose(connect(mapStateToProps))(LoginGate);
