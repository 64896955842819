import {completed, LOAD_PLANTS} from "../actions/names";

const DEFAULT_STATE = {
  plants: [],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case completed(LOAD_PLANTS): {
      return {
        ...state,
        plants: action.payload,
      }
    }
    default: {
      return state;
    }
  }
}
