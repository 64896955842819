import React from "react";
import InputBox from "./InputBox";
import {compose} from "redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const PrintPlant = ({plants}) => {
    
    return <InputBox columns={[530]}>
    <h3>Print Plants</h3>   
    <div style={{gridColumn: "1 / 3", gridRow: 6, display: "flex"}}>
        <button style={{margin:10}} onClick={() => printSubscriptions(plants)}>Print Plant List</button>
    </div>
    </InputBox>
};

const mapStateToProps = createStructuredSelector({
    plants: state => state.plants.plants,
});

const printSubscriptions = (plants) => {

    var body = document.createElement('body');

    plants.forEach((plant) => {     
        var plantContent = document.createElement('div');
        plantContent.style.marginBottom = '15px';
        plantContent.innerHTML = '<p>'+ plant.plantName + ":     " + plant.subscriptionDeadline+'</p>';
        body.appendChild(plantContent);
    });

    var printWindow = window.open('', 'Print', 'heigh=800,width=1200');
    printWindow.document.title = "Betonwerk-Abonnement-Laufzeiten";
    printWindow.document.body.innerHTML = body.innerHTML;

    printWindow.print();
    printWindow.close();
}

export default compose(connect(mapStateToProps))(PrintPlant);
