import React, {useState} from "react";
import InputBox from "./InputBox";
import {compose} from "redux";
import {connect} from "react-redux";
import {registerUser} from "../actions";

const DEFAULT_PASSWORD = "Durlach76227";

const RegisterUser = ({dispatch}) => {
  const [newMail, setNewMail] = useState("");
  const [initialPassword, setInitialPassword] = useState(DEFAULT_PASSWORD);
  const [systemAdmin, setSystemAdmin] = useState(false);

  const register = () => {
    dispatch(registerUser(newMail, initialPassword, systemAdmin));
    setNewMail("");
    setInitialPassword(DEFAULT_PASSWORD);
    setSystemAdmin(false);
  };

  return <InputBox columns={[530]}>
    <h3>Register new user</h3>

    <label style={{gridColumn:1, gridRow:2, margin: 3}}>User:</label>
    <input style={{gridColumn:1, gridRow:3, margin: 3}}
           type={"text"}
           value={newMail}
           placeholder={"A??.???.N"}
           onChange={event => setNewMail(event.target.value)}/>

    <label style={{gridColumn:1, gridRow:4, margin: 3}}>Password:</label>
    <input style={{gridColumn:1, gridRow:5, margin: 3}}
           value={initialPassword}
           onChange={event => setInitialPassword(event.target.value)}
    />

    <div style={{gridColumn:1, gridRow:6, margin: 3}}>
      <input type={"checkbox"} style={{gridColumn:2, gridRow:5, margin: 3}}
             checked={systemAdmin}
             onChange={(event) => setSystemAdmin(event.target.checked)}/>
      <label>System Administrator (Wiggert only! Can modify plants and users)</label>
    </div>

    <div style={{gridColumn: "1 / 3", gridRow:7}}>
      <button style={{margin:10}} onClick={register}>
        Register
      </button>
    </div>
  </InputBox>
};

export default compose(connect())(RegisterUser);
