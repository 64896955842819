import React from "react";
import star from "./star.png";

const UserEntry = ({eMail, isAdmin}) => {
  return <>
      {eMail}
      {isAdmin &&
      <img style={{marginLeft: 5}} src={star} alt={"Admin"}/>}
    </>
};

export default UserEntry;
