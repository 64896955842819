import {
  APPLICATION_INIT, CLEAR_ERRORS,
  completed,
  failed,
  LOAD_PLANTS,
  LOAD_USERS,
  LOGIN, LOGOFF,
  SUBSCRIPTION_DIALOG_OPEN,
  CHANGE_PLANT_SUBSCRIPTION,
  pending,
  REGISTER_PLANT,
  REGISTER_USER, ADD_USER_PERMISSION, REMOVE_USER_PERMISSION, REMOVE_USER, UPLOAD_PLANT_LOGO, CHANGE_PLANT_DISPLAYNAME, REMOVE_PLANT
} from "./names";

export const logOff = () => {
  return {
    type: LOGOFF,
  }
};

const loadJson= (dispatch, actionName, url, token) => {
  dispatch({
    type: pending(actionName)
  });

  const settings = {
    headers: {
      "Authorization": "Bearer " + token
    }
  };

  fetch(url, settings)
    .then(response => {
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          dispatch(logOff());
          throw Error("Not authorized");
        }
        throw Error("Response status was " + response.status)
      }
      return response.json()
    })
    .then(json => {
      dispatch({
        type: completed(actionName),
        payload: json,
      })
    })
    .catch(error => {
      dispatch({
        type: failed(actionName),
        payload: error.message
      })
    })
};

const loadUsers = (dispatch, token) => {
  loadJson(dispatch, LOAD_USERS, "/api/user/list", token);
};

const loadPlants = (dispatch, token) => {
  loadJson(dispatch, LOAD_PLANTS, "/api/plant/list", token);
};

const loadAll = (dispatch, token) => {
  loadUsers(dispatch, token);
  loadPlants(dispatch, token);
};

export const applicationInit = () => (dispatch, getState) => {
  dispatch({
    type: APPLICATION_INIT
  });

  const token = getState().application.loginToken;
  loadAll(dispatch, token);
};

const post = (url, payload, token = undefined) => {
  const headers = {
    "Content-Type": "application/json",
  };

  // Add optional auth token
  if (token) {
    headers["Authorization"] = "Bearer " + token;
  }

  const settings = {
    method: "POST",
    cache: "no-cache",
    headers: headers,
    body: JSON.stringify(payload)
  };

  return fetch(url, settings)
};

const pushChange  = (endpoint, baseActionName, payload) => (dispatch, getState) => {
  dispatch({
    type:pending(baseActionName),
    payload,
  });

  const token = getState().application.loginToken;

  post(endpoint, payload, token)
    .then(response => {
      if (!response.ok) {
        throw Error("Server returned " + response.statusText);
      }
      dispatch({
        type: completed(baseActionName),
        payload,
      });

      // Nothing useful in here, just reload the lists
      loadAll(dispatch, token)
    })
    .catch(error => {
      dispatch({
        type: failed(baseActionName),
        payload,
        error: error.message,
      })
    })
};

export const registerPlant  = (newPlant, plantPassword) => {
  const payload = {
    plantPassword: plantPassword,
    plant: newPlant,
  };

  return pushChange("/api/plant/register", REGISTER_PLANT, payload);
};

export const registerUser = (eMail, password, systemAdmin) => {
  const payload = {
    eMail,
    password,
    systemAdmin,
  };

  return pushChange("/api/user/register", REGISTER_USER, payload);
};

export const removeUser = (user) => {
  const payload = {
    eMail: user,
  };

  return pushChange("/api/user/remove", REMOVE_USER, payload);
};

export const addUserPermission = (eMail, plant, admin) => {
  const payload = {
    eMail,
    plant,
    plantAdmin: admin
  };

  return pushChange("/api/plant/addPermission", ADD_USER_PERMISSION, payload);
};

export const removeUserPermission = (eMail, plant) => {
  const payload = {
    eMail,
    plant,
  };

  return pushChange("/api/plant/removePermission", REMOVE_USER_PERMISSION, payload);
};

export const openSubscriptionDialog = (plantName) => {
  return{
    type: SUBSCRIPTION_DIALOG_OPEN, 
    plantName: plantName,
  };
}

export const closeSubscriptionDialog = (newDate, plant) => {
  const payload = {
    subscriptionDeadline: newDate,
    plantName: plant,
  };

  return pushChange("/api/plant/changeSubscriptionDeadline", CHANGE_PLANT_SUBSCRIPTION, payload)
}

export const removePlant = (plantName) => {
  const payload = {
    plantName,
  };
  return pushChange("/api/plant/remove", REMOVE_PLANT, payload);
}

export const addPlantLogo = (plant, image) => {
  const payload = {
    plant, 
    image,
  };
  return pushChange("/api/plant/plantLogo", UPLOAD_PLANT_LOGO, payload)
}

export const changeDisplayName = (plant, displayName) => {
  const payload = {
    plantName: plant,
    displayName: displayName
  };
  return pushChange("/api/plant/changeDisplayname", CHANGE_PLANT_DISPLAYNAME, payload)
}

export const login = (email, password) => (dispatch) => {
  dispatch({
    type: pending(LOGIN),
  });

  const payload = {
    email,
    password,
  };

  post("/api/login/user", payload)
    .then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          throw Error("Invalid login")
        }
        throw Error("Login failed!");
      }
      return response.json();
    })
    .then(json => {
      dispatch({
        type: completed(LOGIN),
        payload: json.token,
      });
    })
    .catch(error => {
      dispatch({
        type: failed(LOGIN),
        error: error.message,
      })
    })
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
};
