import React, {useState} from "react";
import InputBox from "./InputBox";
import {compose} from "redux";
import {connect} from "react-redux";
import {removeUser} from "../actions";


const RemoveUser = ({dispatch}) => {
  const [user, setUser] = useState("");

  const register = () => {
    dispatch(removeUser(user));
    setUser("");
  };

  return <InputBox columns={[530]}>
    <h3>Delete user</h3>

    <label style={{gridColumn:1, gridRow:2, margin: 3}}>User:</label>
    <input style={{gridColumn:1, gridRow:3, margin: 3}}
           type={"text"}
           value={user}
           placeholder={"A??.???.N"}
           onChange={event => setUser(event.target.value)}/>

    <div style={{gridColumn: "1 / 3", gridRow:4}}>
      <button style={{margin:10}} onClick={register}>
        Delete
      </button>
    </div>
  </InputBox>
};

export default compose(connect())(RemoveUser);