export const APPLICATION_INIT = "APPLICATION_INIT";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_PLANTS = "LOAD_PLANTS";

export const LOGIN = "LOGIN";
export const LOGOFF = "LOGOFF";

export const SUBSCRIPTION_DIALOG_OPEN = "SUBSCRIPTION_DIALOG_OPEN";

export const REGISTER_PLANT = "REGISTER_PLANT";
export const REGISTER_USER = "REGISTER_USER";
export const REMOVE_USER = "REMOVE_USER";
export const ADD_USER_PERMISSION = "ADD_USER_PERMISSION";
export const REMOVE_USER_PERMISSION = "REMOVE_USER_PERMISSION";
export const UPLOAD_PLANT_LOGO = "UPLOAD_PLANT_LOGO";
export const CHANGE_PLANT_SUBSCRIPTION= "CHANGE_PLANT_SUBSCRIPTION";
export const CHANGE_PLANT_DISPLAYNAME= "CHANGE_PLANT_DISPLAYNAME";
export const REMOVE_PLANT = "REMOVE_PLANT";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const pending = (name) => {
  return name + "_PENDING";
};

export const failed = (name) => {
  return name + "_FAILED";
};

export const completed = (name) => {
  return name + "_COMPLETED";
};
