import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {closeSubscriptionDialog} from "../actions";
import {createStructuredSelector} from "reselect";
import Modal from 'react-modal';

const SubscriptionDialog = ({dispatch ,dialogVisibility, plant}) => {
    const dialogStyle = { 
        content: {
            top: "30%",
            left: "40%",
            right: "auto",
            buttom: "auto",       
            width: "20%",
            height: "15%",
        }       
    };

    const textBoxStyle = {
        width: "auto",
        paddingLeft: "6px",
        paddingTop: "6px",
        margin: "3px",
    };

    return <div>
        <Modal style={dialogStyle} isOpen={dialogVisibility} ariaHideApp={false}>
            <h1>Abo-Laufzeit Einstellen</h1>
            <div>
                Neues Abo-Ablaufdatum eingeben:
                <br></br>
                <input id="subscriptionInput" type="date" style={textBoxStyle}/>
            </div>
            <button onClick={() => dispatch(closeSubscriptionDialog(document.getElementById("subscriptionInput").value, plant))}>OK</button>
        </Modal>
    </div>
}

const mapStateToProps = createStructuredSelector({
    dialogVisibility: state => state.application.isSubscriptionDialogOpen,
    plant: state => state.application.subscriptionDialogPlantName,
});

export default compose(connect(mapStateToProps))(SubscriptionDialog);