import React, {useState} from "react";
import InputBox from "./InputBox";
import {connect} from "react-redux";
import {compose} from "redux";
import {addUserPermission} from "../actions";

const AddUserPermission = ({plant, dispatch}) => {
  const [mail, setMail] = useState("");
  const [admin, setAdmin] = useState(false);

  const commit = () => {
    if (mail === "") {
      return;
    }
    dispatch(addUserPermission(mail, plant, admin));
    setMail("");
    setAdmin(false);
  };

  return <InputBox columns={[530]}>
    <h3 style={{gridColumn: "1 / 3", gridRow: 1, margin: 1}}>Assign User</h3>
    <input style={{gridColumn: 1, gridRow: 2, margin: 1}} type={"text"} value={mail}
           onChange={event => setMail(event.target.value)}/>

    {/*<div style={{gridColumn: 1, gridRow: 3, margin: 1}}>*/}
    {/*  <input*/}
    {/*    type={"checkbox"}*/}
    {/*    checked={admin}*/}
    {/*    onChange={event => setAdmin(event.target.checked)}*/}
    {/*  />*/}
    {/*  <label>Plant Admin (can modify plant users)</label>*/}
    {/*</div>*/}

    <div style={{gridColumn: "1/3", gridRow: 3}}>
    <button onClick={commit} style={{margin:10}}>
      Add plant user
    </button>
    </div>
  </InputBox>
};

export default compose(connect())(AddUserPermission);
