import React, {useState} from "react";
import InputBox from "./InputBox";
import {compose} from "redux";
import {connect} from "react-redux";
import {registerPlant} from "../actions";

const DEFAULT_PLANT_PASSWORD = "";

const RegisterPlant = ({dispatch}) => {

  const [newPlant, setNewPlant] = useState("");
  const [plantPassword, setPlantPassword] = useState(DEFAULT_PLANT_PASSWORD);

  const register = () => {
    dispatch(registerPlant(newPlant, plantPassword));
    setNewPlant("");
    setPlantPassword(DEFAULT_PLANT_PASSWORD);
  };

  return <InputBox columns={[530]}>
    <h3>Register new plant</h3>
    <label style={{gridColumn: 1, gridRow: 2, margin: 3}}>Plant Name / A-Number:</label>
    <input style={{gridColumn: 1, gridRow: 3, margin: 3}} type={"text"}
           value={newPlant}
           placeholder={"A??.???"}
           onChange={event => setNewPlant(event.target.value)}/>
    <label style={{gridColumn: 1, gridRow: 4, margin: 3}}>Plant Password:</label>
    <input style={{gridColumn: 1, gridRow: 5, margin: 3}} type={"text"}
           value={plantPassword}
           placeholder={"Leave empty to auto-generate"}
           onChange={event => setPlantPassword(event.target.value)}/>
    <div style={{gridColumn: "1 / 3", gridRow: 6, display: "flex"}}>
      <button style={{margin:10}} onClick={register}>Register</button>
    </div>
  </InputBox>
};

export default compose(connect())(RegisterPlant);
