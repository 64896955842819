import React from "react";
import {Provider} from "react-redux";
import App from "./App";
import LoginGate from "./LoginGate";

const Root = ({store}) => {
  return <Provider store={store}>
    <LoginGate>
      <App/>
    </LoginGate>
  </Provider>;
};

export default Root;
