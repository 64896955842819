import {completed, LOAD_USERS} from "../actions/names";

const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case completed(LOAD_USERS): {
      return action.payload.users;
    }
    default: {
      return state;
    }
  }
}
