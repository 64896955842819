import {completed, SUBSCRIPTION_DIALOG_OPEN, failed, LOGIN, LOGOFF, CHANGE_PLANT_SUBSCRIPTION} from "../actions/names";

const DEFAULT_STATE = {
  loginToken: null,
  loginError: null,
  subscriptionDialogPlantName: null,
  isSubscriptionDialogOpen: false,
}; 

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case completed(LOGIN): {
      return {
        ...state,
        loginToken: action.payload,
        loginError: null,
      }
    }
    case failed(LOGIN): {
      return {
        ...state,
        loginError: action.error
      }
    }
    case LOGOFF: {
      return {
        ...state,
        loginToken: null,
      }
    }
    case SUBSCRIPTION_DIALOG_OPEN: {
      return {
        ...state,
        isSubscriptionDialogOpen: true,
        subscriptionDialogPlantName: action.plantName
      }
    }
    case completed(CHANGE_PLANT_SUBSCRIPTION): {
      return {
        ...state,
        isSubscriptionDialogOpen: false,
        subscriptionDialogPlantName: null
      }
    }
    default: {
      return state;
    }
  }
}
