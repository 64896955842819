import React from "react";
import {createStructuredSelector} from "reselect";
import {compose} from "redux";
import {connect} from "react-redux";
import RegisterUser from "./RegisterUser";
import UserEntry from "./UserEntry";
import RemoveUser from "./RemoveUser";

const UserList = ({users}) => {
  const style = {
    margin: 10,
  };
  return <div style={style}>
    <h2>Users</h2>
    <ol>
      {users.map((user, index) => <li key={index}>
        <UserEntry eMail={user.eMail} isAdmin={user.systemAdmin}/>
      </li>)}
    </ol>
    <div style={{marginBottom:20}}>
      <RegisterUser/>
    </div>
    <div>
      <RemoveUser/>
    </div>
  </div>
};

const mapStateToProps = createStructuredSelector({
  users: state => state.users
});

export default compose(connect(mapStateToProps))(UserList);