import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {clearErrors} from "../actions";

const ErrorList = ({errorList, dispatch}) => {
  if (!errorList.length) {
    return null;
  }
  return <div style={{marginLeft: 10, color: "#f00"}}>
    <h3>Errors</h3>
    {errorList.map((each, index) => <div key={index}>{each}</div>)}
    <button onClick={() => dispatch(clearErrors())}>Clear</button>
    </div>
};

const mapStateToProps = createStructuredSelector({
  errorList: state => state.errorList.list
});

export default compose(connect(mapStateToProps))(ErrorList);
