
import React, {useState} from "react";
import {login} from "../actions";
import {compose} from "redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const LoginError = ({error}) => {
  if (!error) {
    return null;
  }

  const outerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontWeight: "bold",
    color: "#aa0000",
  };
  return <div style={outerStyle}>{error}</div>
};

const LoginForm = ({dispatch}) => {

  const outerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  };
  const style = {
    display: "flex",
    flexDirection: "column",
    margin: 100,
    minWidth: 300,
  };

  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  return <div style={outerStyle}>
      <div style={style}>
        <h1>App Administration Login</h1>
        <label>User:</label>
        <input type={"text"} value={mail} onChange={event => setMail(event.target.value)} />
        <label>Password:</label>
        <input type={"password"} value={password} onChange={event => setPassword(event.target.value)}/>
        <button onClick={() => dispatch(login(mail, password))}>Log in</button>
      </div>
    </div>
};

const LoginScreen = ({dispatch, loginError}) => {
  return <>
    <LoginForm dispatch={dispatch}/>
    <LoginError error={loginError}/>
    </>
};

const mapStateToProps = createStructuredSelector({
  loginError: state => state.application.loginError,
});

export default compose(connect(mapStateToProps))(LoginScreen);
