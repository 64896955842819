import React, {useEffect} from 'react';
import './App.css';
import {applicationInit, logOff} from "../actions";
import {connect} from "react-redux";
import {compose} from "redux";
import UserList from "./UserList";
import PlantList from "./PlantList";
import ErrorList from "./ErrorList";
import SubscriptionDialog from "./SubscriptionDialog";

const Header = ({dispatch}) => {
  const logoutStyle = {
    fontSize: 12,
    margin: 5
  };
  return <header className="App-header">
    <h1>App Administration</h1>
    <button style={logoutStyle} onClick={() => dispatch(logOff())}>Logout</button>
  </header>
};

const App = ({dispatch}) => {
  useEffect(() => {
    dispatch(applicationInit())
  }, [dispatch]);
  return (
      <div>
        <SubscriptionDialog/>
        <Header dispatch={dispatch}/>
        <ErrorList/>
        <PlantList/>
        <UserList/>
      </div>
  );
};

export default compose(connect())(App);
