import React from "react";

const InputBox = ({columns, children}) => {


  const container = {
    display: "grid",
    width: columns.reduce((lhs, rhs) => lhs + rhs),
    gridTemplateColumns: columns.map(x => x+"px").join(" "),
    border: "1px solid",
    borderRadius: "1px",
    background: "#fff2e2",
    alignItems: "center",
    padding: 4,
  };

  return <div style={container}>
    {children}
  </div>
};

export default InputBox
